import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Components
import { Link, graphql } from "gatsby"

const SnippetsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title, description },
    },
  },
}) => (
  <div>
    <Layout title="Shanejix Blog" pageName="Snippets">
      <SEO title="snippets" />
      <Helmet
        htmlAttributes={{ lang: "en" }}
        meta={[{ name: "description", content: description }]}
        title={title}
      />
      <article
        style={{
          // border: `1px solid black`,
          borderRadius: `3px`,
          paddingTop: '3.5rem',
          marginBottom: `0.5rem`,
          // background: `white`,
          fontSize: `1rem`,
        }}
      >
        <h2>Snippets</h2>
        <p>Short solutions to discrete problems which can be copied and pasted.</p>
        <ul>
          {
            group.reverse().map((item, idx) => {
              const currSnippet = item.edges[0].node || {};
              const slug = currSnippet.fields.slug;
              const title = currSnippet.frontmatter.title;
              const date = currSnippet.frontmatter.date;
              const tags = currSnippet.frontmatter.tags;

              if (tags.includes('Snippet')) {
                return (
                  <li key={idx}>
                    <Link to={`/posts${slug}`}>
                      {title}
                    </Link>
                    <span style={{
                      padding: '0 5px'
                    }}>
                      [
                      {(tags.filter((tag => tag !== 'Snippet')).map(tag => {
                        return (
                          <span
                            style={{
                              padding: '0 2px'
                            }}
                          >
                            <Link

                              to={`/tags/${tag}`}
                            >
                              {tag}
                            </Link>
                          </span>
                        )
                      }))}
                      ]
                    </span>
                    <span>[{date}]</span>
                  </li>
                )
              }
            })
          }
        </ul>
      </article>
    </Layout>
  </div>
)

export default SnippetsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___date) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              date
              title
              update
              tags
            }
          }
        }
      }
    }
  }
`
